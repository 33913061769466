import safe from 'mdast-util-to-markdown/lib/util/safe';

function fromMarkdown() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var permalinks = opts.permalinks || [];

  var defaultPageResolver = function defaultPageResolver(name) {
    return [name.replace(/ /g, '_').toLowerCase()];
  };

  var pageResolver = opts.pageResolver || defaultPageResolver;
  var newClassName = opts.newClassName || 'new';
  var wikiLinkClassName = opts.wikiLinkClassName || 'internal';

  var defaultHrefTemplate = function defaultHrefTemplate(permalink) {
    return "#/page/".concat(permalink);
  };

  var hrefTemplate = opts.hrefTemplate || defaultHrefTemplate;

  function enterWikiLink(token) {
    this.enter({
      type: 'wikiLink',
      value: null,
      data: {
        alias: null,
        permalink: null,
        exists: null
      }
    }, token);
  }

  function top(stack) {
    return stack[stack.length - 1];
  }

  function exitWikiLinkAlias(token) {
    var alias = this.sliceSerialize(token);
    var current = top(this.stack);
    current.data.alias = alias;
  }

  function exitWikiLinkTarget(token) {
    var target = this.sliceSerialize(token);
    var current = top(this.stack);
    current.value = target;
  }

  function exitWikiLink(token) {
    var wikiLink = this.exit(token);
    var pagePermalinks = pageResolver(wikiLink.value);
    var permalink = pagePermalinks.find(function (p) {
      return permalinks.indexOf(p) !== -1;
    });
    var exists = permalink !== undefined;

    if (!exists) {
      permalink = pagePermalinks[0];
    }

    var displayName = wikiLink.value;

    if (wikiLink.data.alias) {
      displayName = wikiLink.data.alias;
    }

    var classNames = wikiLinkClassName;

    if (!exists) {
      classNames += ' ' + newClassName;
    }

    wikiLink.data.alias = displayName;
    wikiLink.data.permalink = permalink;
    wikiLink.data.exists = exists;
    wikiLink.data.hName = 'a';
    wikiLink.data.hProperties = {
      className: classNames,
      href: hrefTemplate(permalink)
    };
    wikiLink.data.hChildren = [{
      type: 'text',
      value: displayName
    }];
  }

  return {
    enter: {
      wikiLink: enterWikiLink
    },
    exit: {
      wikiLinkTarget: exitWikiLinkTarget,
      wikiLinkAlias: exitWikiLinkAlias,
      wikiLink: exitWikiLink
    }
  };
}

function toMarkdown() {
  var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var aliasDivider = opts.aliasDivider || ':';
  var unsafe = [{
    character: '[',
    inConstruct: ['phrasing', 'label', 'reference']
  }, {
    character: ']',
    inConstruct: ['label', 'reference']
  }];

  function handler(node, _, context) {
    var exit = context.enter('wikiLink');
    var nodeValue = safe(context, node.value, {
      before: '[',
      after: ']'
    });
    var nodeAlias = safe(context, node.data.alias, {
      before: '[',
      after: ']'
    });
    var value;

    if (nodeAlias !== nodeValue) {
      value = "[[".concat(nodeValue).concat(aliasDivider).concat(nodeAlias, "]]");
    } else {
      value = "[[".concat(nodeValue, "]]");
    }

    exit();
    return value;
  }

  return {
    unsafe: unsafe,
    handlers: {
      wikiLink: handler
    }
  };
}

export { fromMarkdown, toMarkdown };
